import React from "react";
import chatIcon from "../../assets/images/svg/chet_icon.svg";
import faqIcon from "../../assets/images/svg/faq_icon.svg";
import QuestionsIcon from "../../assets/images/svg/question_icon.svg";
import CustmerCareSupportIcon from "../../assets/images/svg/supportsIcon.svg";
import { Link } from "react-router-dom";

const SupportHero = () => {
  return (
    <div className="">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between mt-4 gap-3">
          <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
            Deposit
          </button>
          <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
            Withdrawal
          </button>
        </div>
        <h5 className="mb-0 fs_16 fw-medium ff_heebo text-white mt-4">
          Contact & Support
        </h5>
        <Link to="/support-chat">
          <div className="d-flex mt-3 align-items-cente gap-2 bg_clr_dark_grey_3 p-4 rounded-2">
            <img src={chatIcon} alt="chatIcon" />
            <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white pt-1">
              Chat
            </h5>
          </div>
        </Link>
        <div className="d-flex mt-3 align-items-cente gap-2 bg_clr_dark_grey_3 p-4 rounded-2">
          <img src={faqIcon} alt="chatIcon" />
          <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white pt-1">FAQ</h5>
        </div>
        <div className="d-flex mt-3 align-items-cente gap-2 bg_clr_dark_grey_3 p-4 rounded-2">
          <img src={QuestionsIcon} alt="chatIcon" />
          <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white pt-1">
            5 Most Asked Questions
          </h5>
        </div>
        <div className="text-center my-5">
          <img src={CustmerCareSupportIcon} alt="CustmerCareSupportIcon" />

          <h5 className=" mt-3 fs_16 ff_heebo fw-medium text-white mb-0">
            Custmer Care Support
          </h5>
          <h5 className="fs_20 ff_heebo fw-medium text-white mt-2">
            +91 99898 98081
          </h5>
        </div>
      </div>
    </div>
  );
};

export default SupportHero;
