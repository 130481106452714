import {
    createSlice,
} from "@reduxjs/toolkit";

const UserLocationSlice = createSlice({
    name: "userLocation",
    initialState: {
        location: null,
        error: null,
        loading: false,
    },
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload;
        },
    },
});

export const { setLocation } = UserLocationSlice.actions;

export default UserLocationSlice.reducer;
