import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
export const GetBanner = async (data) => {
    const res = await axios({
        url: baseUrl + "/get_banner",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
        data
    });
    console.log(res);
    if (res.data.status) {
        return res.data;
    } else {
        return res.data;
    }
}