import axios from "axios";

const Url = process.env.REACT_APP_BASEURL;
export const getAllCasion = () => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/CaisnoTry" })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getLiveGameUrl = async (code, playerId, funMode) => {
  const res = await axios({
    url: Url + "/lauchGame",
    method: "POST",
    data: {
      code: code,
      playerId: playerId,
      funMode: funMode,
    },
  });
  return res.data;
};
