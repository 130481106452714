import React from "react";
import trophy from "../../assets/images/svg/trophy.svg";
import casinoCoins from "../../assets/images/svg/casinoCoins.svg";
import { useNavigate } from "react-router-dom";

const NewHomeGameOpction = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="container">
        <div className="d-flex gap-4 align-items-center">
          <button
            className="rounded-4 border-0 yellow_linear w-100 py-3 text-black ff_inter fw-800 fs_17"
            onClick={() => {
              navigate("/sports");
            }}
          >
            Sports
            <img className="ms-2" src={trophy} alt="trophy" />
          </button>
          <button
            className="rounded-4 blue_linear border-0 w-100 py-3 text-white ff_inter fw-800 fs_17"
            onClick={() => {
              navigate("/casino-page");
            }}
          >
            Casino
            <img className="ms-2" src={casinoCoins} alt="trophy" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewHomeGameOpction;
