import { configureStore } from "@reduxjs/toolkit";
import CircketSlice from "../Cricket/CircketSlice";
import SoccerSlice from "../Soccer/SoccerSlice";
import SelectedEvent from "../SingleEvents/SelectedEvent";
import CasinoSlice from "../Casino/CasinoSlice";
import UserSlice from "../User/UserSlice";
import EventSlice from "../AllEvent/EventSlice";
import DeviceLocationSlice from "../Location/DeviceLocationSlice";
import SportsRadarSlice from "../SportsRadar/SportsRadarSlice";

const store = configureStore({
  reducer: {
    Cricket: CircketSlice,
    Soccer: SoccerSlice,
    SelectedEvent: SelectedEvent,
    Casino: CasinoSlice,
    User: UserSlice,
    Events: EventSlice,
    Location : DeviceLocationSlice,
    SportsRadar : SportsRadarSlice
  },
});

export default store;
