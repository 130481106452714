import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import searchIcon from "../../assets/images/svg/searchIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllSports } from "../../Services/events";
import { GetDateDescription, TimePending } from "../../Services/Common";
import { useDispatch, useSelector } from "react-redux";
import { setEvent } from "../../redux/SingleEvents/SelectedEvent";
import cross_icon from "../../assets/images/svg/cross_icon.svg";

function SearchModal({ open, close }) {
  const inputRef = useRef(null);
  const [sportData, setSportData] = useState([]);
  const [value, setValue] = useState("");
  const [filterSportData, setFilterSportData] = useState([]);
  const [filterCasinoData, setFilterCasinoData] = useState([]);
  const Casino = useSelector((state) => state.Casino.events);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const getSportEvent = () => {
    getAllSports().then((res) => {
      setSportData(res);
    });
  };

  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/sports" ||
      pathname == "/bet-history"
    ) {
      if (value) {
        const filtered = sportData.filter((event) =>
          event?.event?.name?.toLowerCase().includes(value.toLowerCase())
        );
        setFilterSportData(filtered);
      } else {
        setFilterSportData([]);
      }
    }

    if (
      pathname === "/" ||
      pathname === "/casino-page" ||
      pathname == "/bet-history" ||
      pathname == "/Betting"
    ) {
      if (value) {
        const filtered = Casino?.games?.filter((event) =>
          event?.name?.toLowerCase().includes(value.toLowerCase())
        );
        setFilterCasinoData(filtered);
      } else {
        setFilterCasinoData([]);
      }
    }
  }, [value, sportData, Casino, pathname]);

  useEffect(() => {
    if (open) {
      if (pathname === "/" || pathname === "/sports") {
        getSportEvent();
      }
    }
  }, [open, pathname]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <div>
      <Modal show={open} onHide={close} className="bg_modal">
        <Modal.Header className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <div className="px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={close}
              className="position-absolute end-0 top-0 cursor-pointer"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <div className="search-input-div rounded-2 bg_clr_dark_grey_3 w-100 d-flex align-items-center px-3 mt-4">
              <input
                ref={inputRef}
                className="bg-transparent border-0 search-input ff_heebo fs_16 text-white opacity-26 w-100"
                type="text"
                placeholder="Search Your Game"
                onChange={(e) => setValue(e.target.value)}
              />
              <img
                style={{ width: "18px" }}
                src={searchIcon}
                alt="searchicon"
              />
            </div>

            <div>
              {pathname === "/" ||
              pathname === "/sports" ||
              pathname == "/bet-history" ||
              pathname == "/Betting" ? (
                <div>
                  <div></div>
                  {filterSportData.length > 0 ? (
                    <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                      <span className="text-white">Sport result</span>
                      <div>
                        {filterSportData.map((value, index) => (
                          <div
                            key={index}
                            className="matches-di rounded-2 px-3 py-4"
                            onClick={() => {
                              dispatch(setEvent(value));
                              navigate("/Betting");
                            }}
                          >
                            <div>
                              <p className="text-white mb-0 ff_inter fw-medium fs_12">
                                {value?.series?.name}
                              </p>
                              <div className="d-flex align-items-center justify-content-between mt-4 ">
                                <div className="text-centr">
                                  <p className="mb-0 text-white ff_inter fs_11 fw-medium ">
                                    {value?.runners[0]?.name} <br /> vs <br />
                                    {value?.runners[1]?.name}
                                  </p>
                                </div>
                                <div className="text-center">
                                  <p className="mb-0 text-white ff_inter fs_10 fw-medium">
                                    {GetDateDescription(
                                      value?.event?.startDate
                                    )}
                                  </p>
                                  <p className="mb-0 clr_green ff_inter fs_10 fw-bold">
                                    {TimePending(value?.event?.startDate)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="events-hr-line mt-3"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                      {value !== "" ? (
                        <div className="d-flex justify-content-center">
                          <Spinner size="sm" variant="success" />
                        </div>
                      ) : (
                        <div>
                          <div>
                            <span className="text-white">Sport result</span>
                          </div>
                          <span className="text-white">Nothing to show</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : null}

              {pathname === "/" ||
              pathname === "/casino-page" ||
              pathname == "/bet-history" ||
              pathname == "/Betting" ? (
                <div>
                  {filterCasinoData.length > 0 ? (
                    <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                      <div>
                        <span className="text-white">Casnio result</span>
                      </div>
                      <Row>
                        {filterCasinoData.map((value, index) => (
                          <Col xs={6} key={index}>
                            <div
                              className="pt-4"
                              onClick={() => {
                                navigate(
                                  "/casino-player/" +
                                    value.code +
                                    "?fn=" +
                                    value?.fun_mode
                                );
                              }}
                            >
                              <div>
                                <img
                                  className="w-100 object-fit-cover rounded"
                                  src={`https://luckmedia.link/${value.code}/thumb_1_1.webp`}
                                  alt={value.sports}
                                />
                              </div>
                              <div className="text-white">{value?.name}</div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : (
                    <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                      {value !== "" ? (
                        <div className="d-flex justify-content-center">
                          <Spinner size="sm" variant="success" />
                        </div>
                      ) : (
                        <div>
                          <div>
                            <span className="text-white">Casnio result</span>
                          </div>
                          <span className="text-white">Nothing to show</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SearchModal;
