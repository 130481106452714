import React from "react";
import { Col, Row } from "react-bootstrap";
import operator from "../../assets/images/png/verifiedOperatore.png";
import ufc from "../../assets/images/png/ufc.png";
import brb from "../../assets/images/png/brb.png";
import chelsea from "../../assets/images/png/chelsa.png";

const Sponsers = () => {
  return (
    <div className="mt-4 pt-1 pb-2">
      <div className="pb-3">
        <h2
          style={{ lineHeight: "21px" }}
          className="ff_inter fw-semibold fs_16 text-white"
        >
          Sponsorship and Gaming <br /> Responsibilities
        </h2>
        <Row className="my-4 align-items-center ">
          <Col xs={3}>
            <img className="w-100" src={operator} alt="operator" />
          </Col>
          <Col xs={3}>
            <img className="w-100" src={ufc} alt="ufc" />
          </Col>
          <Col className="" xs={3}>
            <img className="w-100" src={brb} alt="brb" />
          </Col>
          <Col className="" xs={3}>
            <img className="w-100" src={chelsea} alt="chelsea" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Sponsers;
