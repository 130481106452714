import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
export const GetSportsCategory = () => {
  return new Promise((resolve, reject) => {
    axios({ url: baseUrl + "/getRedarSports" })
      .then((res) => {
        resolve(res.data.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};
