import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

export const GetAllDepositRequest = async (data) => {
    const res = await axios({
        url: baseUrl + "/get_deposit_history",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
        data,
    });
    if (res.data.status) {
        return res.data.data;
    } else {
        return [];
    }

}
export const GetAllBonus = async () => {
    const res = await axios({
        url: baseUrl + "/get_bonus",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
    });
    if (res.data.status) {
        return res.data.data;
    } else {
        return [];
    }

}

export const GetAllWidtharalRequest = async (data) => {
    const res = await axios({
        url: baseUrl + "/get_withdrawal_history",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
        data,
    });
    if (res.data.status) {
        return res.data.data;
    } else {
        return [];
    }
}


export const claimBonus = async (data) => {
    const res = await axios({
        url: baseUrl + "/collect_bonus",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
        data,
    });
    if (res.data.status) {
        return res.data.data;
    } else {
        return [];
    }
}

export const GetLoyaltyBouns = async () => {
    const res = await axios({
        url: baseUrl + "/get_active_loyalty_bonus",
        method: "GET",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
    });
    if (res.data.status) {
        return res.data.data;
    } else {
        return [];
    }
}