import AppNavbar from "../components/home/AppNavbar";
import speaker from "../assets/images/svg/speaker.svg";
import EventsToBet from "../components/betting/EventsToBet";
import Appfooter from "../components/footer/Appfooter";
import CommenButton from "../components/CommenButton";
import { useEffect } from "react";

const Matches = () => {
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div>
      <div className="container px-3 mt-3">
        <div className="bg_clr_dark_grey_3 p-2 rounded-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img style={{ width: "30px" }} src={speaker} alt="speaker" />
              <p className="mb-0 text-white ff_heebo fs_11">
                IPL Cup Winner Market Available On Our Exchange...
              </p>
            </div>
          </div>
        </div>
        <CommenButton />
      </div>
      <EventsToBet />
      <Appfooter />
    </div>
  );
};

export default Matches;
