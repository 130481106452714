import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addBet } from '../../redux/User/UserSlice';

function SportsRadarFancyCompenet({ allFancy, allOddsMarkets, activeRipple, setBetModal, setLoginModal, setLowBalanceAlert, event, selectedSeries }) {
    const dispatch = useDispatch()
    const UserBalance = useSelector((state) => state.User.balance);
    const CurrentUser = useSelector((state) => state.User.User);

    return (

        <div>
            <div className="container mt-3">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="d-flex align-items-center justify-content-between text-white ff_inter fw-medium fs_16">
                            Fancy
                        </Accordion.Header>
                        <Accordion.Body>
                            {allFancy
                                ?.filter((value) => value.status === "OPEN")
                                ?.map((value, index) => {
                                    const oddsMarket = allOddsMarkets[value?.marketId];
                                    return (
                                        <div key={index}>
                                            <div className="pb-1">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="ff_heebo fs_12 mb-1 text-white">
                                                            {value?.marketName + " " + value.marketId}
                                                        </p>
                                                        <div className="d-flex gap-2">
                                                            <p className="ff_heebo fs_12 mb-1 text-white text-nowrap">
                                                                Min : {value?.marketCondition?.minBet}
                                                            </p>
                                                            <p className="ff_heebo fs_12 mb-1 text-white text-nowrap">
                                                                Max : {value?.marketCondition?.maxBet}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {oddsMarket?.runners?.slice(0, 2)?.map((item, ind) => {
                                                        return (
                                                            <div key={ind}>
                                                                <span className="text-white fs_12">
                                                                    {value?.runners[ind]?.name}
                                                                </span>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    {item.status === "ACTIVE" ? (
                                                                        <div
                                                                            style={{ height: "32px" }}
                                                                            className="d-flex align-items-center w-100 gap-2 mt-2 mb-3"
                                                                        >
                                                                            {item?.back?.map((odd, inde) => (
                                                                                <div
                                                                                    key={inde}
                                                                                    className={`w-100 text-black text-center blue-clr-div py-1 px-2 ${activeRipple[`${item.selectionId}-back`] ? 'ripple' : ''
                                                                                        }`}
                                                                                    onClick={() => {
                                                                                        if (CurrentUser) {

                                                                                            if (UserBalance < value?.marketCondition?.minBet) {
                                                                                                setLowBalanceAlert(true);
                                                                                            } else {
                                                                                                setBetModal(true);
                                                                                                console.log(selectedSeries)
                                                                                                const data = {
                                                                                                    Teams: event,
                                                                                                    selectedBet: value.bettingType == "ODDS" ? odd.line == null || odd.line == 0 ? odd.price : odd.line : odd.line == 0 ? odd.price : odd.line,
                                                                                                    odd: value,
                                                                                                    min: value?.marketCondition?.minBet,
                                                                                                    max: value?.marketCondition?.maxBet,
                                                                                                    type: "betfair",
                                                                                                    betType: "back",
                                                                                                    eventId: value.marketId,
                                                                                                    slecetedId: item?.selectionId,
                                                                                                    apiType: "fancy",
                                                                                                    odd_type: value?.bettingType,
                                                                                                    series: selectedSeries,
                                                                                                    seletedTeam: value?.runners[ind]?.name,
                                                                                                    show_profit: value.bettingType == "ODDS" ? true : false,
                                                                                                    type2: value.bettingType == "ODDS" ? "bookmaker" : "odd",
                                                                                                    bhav: value.bettingType !== "ODDS" ? odd.line == 0 ? odd.size : odd.price : 0
                                                                                                };
                                                                                                dispatch(addBet(data));
                                                                                            }
                                                                                        } else {
                                                                                            setLoginModal(true)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {value.bettingType == "ODDS" ?
                                                                                        <div>
                                                                                            <p className="mb-0 ff_heebo fw-bold fs_15  ">
                                                                                                {odd.line == null || odd.line == 0 ? odd.price : odd.line || "-"}
                                                                                            </p>
                                                                                            <p className="mb-0 ff_heebo fs_9">
                                                                                                {odd.line == null || odd.line == 0 ? odd.size : odd.price || "-"}
                                                                                            </p>
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <p className="mb-0 ff_heebo fw-bold fs_15 ">
                                                                                                {odd.line == 0 ? odd.price : odd.line || "-"}
                                                                                            </p>
                                                                                            <p className="mb-0 ff_heebo fs_9">
                                                                                                {odd.line == 0 ? odd.size : odd.price || "-"}
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            ))}

                                                                            {value?.runners[0]?.name !== "ODD" || value?.runners[1]?.name !== "EVEN" ? (
                                                                                item?.lay?.map((odd, inde) => (
                                                                                    <div
                                                                                        key={inde}
                                                                                        className={`w-100 text-black text-center pink-clr-div py-1 px-2 ${activeRipple[`${item.selectionId}-lay`] ? 'ripple' : ''
                                                                                            }`}
                                                                                        onClick={() => {
                                                                                            if (CurrentUser) {

                                                                                                if (UserBalance < value?.marketCondition?.minBet) {
                                                                                                    setLowBalanceAlert(true);
                                                                                                } else {
                                                                                                    setBetModal(true);
                                                                                                    console.log(selectedSeries)
                                                                                                    const data = {
                                                                                                        Teams: event,
                                                                                                        selectedBet: value.bettingType == "ODDS" ? odd.line == null || odd.line == 0 ? odd.price : odd.line : odd.line == 0 ? odd.price : odd.line,

                                                                                                        odd: value,
                                                                                                        min: value?.marketCondition?.minBet,
                                                                                                        max: value?.marketCondition?.maxBet,
                                                                                                        type: "betfair",
                                                                                                        betType: "lay",
                                                                                                        eventId: value.marketId,
                                                                                                        slecetedId: item?.selectionId,
                                                                                                        odd_type: value?.bettingType,
                                                                                                        series: selectedSeries,
                                                                                                        seletedTeam: value?.runners[ind]?.name,
                                                                                                        show_profit: value.bettingType == "ODDS" ? true : false,
                                                                                                        type2: value.bettingType == "ODDS" ? "bookmaker" : "odd",
                                                                                                        bhav: value.bettingType !== "ODDS" ? odd.line == 0 ? odd.size : odd.price : 0
                                                                                                    };
                                                                                                    dispatch(addBet(data));
                                                                                                }
                                                                                            } else {
                                                                                                setLoginModal(true)
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {value.bettingType == "ODDS" ?
                                                                                            <div>
                                                                                                <p className="mb-0 ff_heebo fw-bold fs_15  ">
                                                                                                    {odd.line == null ? odd.price : odd.line || "-"}
                                                                                                </p>
                                                                                                <p className="mb-0 ff_heebo fs_9">
                                                                                                    {odd.line == null ? odd.size : odd.price || "-"}
                                                                                                </p>
                                                                                            </div>
                                                                                            :
                                                                                            <div>
                                                                                                <p className="mb-0 ff_heebo fw-bold fs_15 ">
                                                                                                    {odd.line == 0 ? odd.price : odd.line || "-"}
                                                                                                </p>
                                                                                                <p className="mb-0 ff_heebo fs_9">
                                                                                                    {odd.line == 0 ? odd.size : odd.price || "-"}
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="position-relative w-100">
                                                                            <div className="w-100">
                                                                                <div
                                                                                    style={{ height: "32px" }}
                                                                                    className="d-flex align-items-center w-100 gap-2 mt-2 mb-3 w-100"
                                                                                >
                                                                                    <div className="text-black text-center blue-clr-div py-1 px-2 w-100">
                                                                                        <p className="mb-0 ff_heebo fw-bold fs_15">
                                                                                            {"-"}
                                                                                        </p>
                                                                                        <p className="mb-0 ff_heebo fs_9">
                                                                                            {"-"}
                                                                                        </p>
                                                                                    </div>

                                                                                    <div className="text-black text-center pink-clr-div py-1 px-2 w-100">
                                                                                        <p className="mb-0 ff_heebo fw-bold fs_15">
                                                                                            {"-"}
                                                                                        </p>
                                                                                        <p className="mb-0 ff_heebo fs_9">
                                                                                            {"-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="position-absolute top-0 suspeded-overlay w-100 overlay-div d-flex align-items-center justify-content-center px-2 text-center">
                                                                                {item?.status}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="match-odds-hr-line my-3"></div>
                                        </div>
                                    );
                                })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default SportsRadarFancyCompenet
