import {
  createSlice,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { GetEventsById } from "../../Services/events";

// Define the async thunk
export const GetSoccerEvents = createAsyncThunk(
  "SeletecdEvent/getSoccerEvents",
  async () => {
    const response = await GetEventsById(1);
    return response; // assuming response is in the correct format
  }
);

const SelectedEventSlice = createSlice({
  name: "SingleEvent",
  initialState: {
    events: null,
    error: null,
    loading: false,
  },
  reducers: {
    setEvent: (state, action) => {
      state.events = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(GetSoccerEvents.fulfilled, (state, action) => {
  //     state.events = action.payload;
  //     state.loading = false;
  //     state.error = null;
  //   });
  // },
});

export const { setEvent } = SelectedEventSlice.actions;

export default SelectedEventSlice.reducer;
