import React, { useEffect } from "react";
import Intrest from "../components/intrestpage/Intrest";

const IntrestPage = () => {
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div>
      <Intrest />
    </div>
  );
};

export default IntrestPage;
