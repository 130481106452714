import React, { useState } from "react";
import live from "../../assets/images/svg/live.svg";
import football from "../../assets/images/svg/eventsGreenFootball.svg";
import calender from "../../assets/images/svg/calender.svg";
import greenLive from "../../assets/images/svg/Greenlive.svg";
import whiteFootball from "../../assets/images/svg/eventsWhiteFootball.svg";
import whiteCalender from "../../assets/images/svg/whiteCalender.svg";
import CricketEvents from "./CricketEvents";
import FootballEvents from "./FootballEvents";
import PopularEvents from "./PopularEvents";

const Events = () => {
  const [activeEvent, setActiveEvent] = useState(0);
  const [isActiveEvent, setIsActiveEvent] = useState(true);
  const [events] = useState([
    {
      img: football,
      img2: whiteFootball,
      name: "Lobby",
    },
    {
      img: greenLive,
      img2: live,
      name: "Live Sports",
    },
    {
      img: calender,
      img2: whiteCalender,
      name: "Upcoming Event",
    },
  ]);
  return (
    <div>
      <div className="container overflow-auto events-scollbar">
        <div className="events-div d-flex align-items-center justify-content-between gap-3 pt-4">
          {events.map((value, index) => (
            <div
              key={index}
              className={`d-flex align-items-center gap-2 px-3 cursor-pointer  ${
                activeEvent === index ? "active-event" : "events"
              }`}
              onClick={() => {
                setActiveEvent(index);
                setIsActiveEvent(true);
                events.forEach((event, i) => {
                  if (i !== index) {
                    setIsActiveEvent(true);
                  }
                });
              }}
            >
              {isActiveEvent === true && activeEvent === index ? (
                <img style={{ width: "19" }} src={value.img2} alt="live" />
              ) : (
                <img style={{ width: "19" }} src={value.img} alt="live" />
              )}
              <p className="mb-0 ff_heebo fw-medium fs_16 text-white text-nowrap">
                {value.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <CricketEvents selected={activeEvent} />
      <FootballEvents selected={activeEvent} />
      <PopularEvents />
    </div>
  );
};

export default Events;
