import axios from "axios";

const Url = process.env.REACT_APP_BASEURL;
export const GetEventsById = (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getGame?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getMatchOdds = (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/Runner?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getBookMakerby = (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRunnerBookmaker?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getBookMaker = (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/enterGame?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getFancyCount = async (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getFancy?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getFancyById = (id) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRunnerFancy?id=" + id })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const getAllSports = () => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/testingApi" })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};
export const getAllLiveSportsEvents = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getLiveGamesSportsRadar", method: "GET" })
      .then((res) => {
        resolve(res.data);
      })
      .then((error) => {
        reject(error);
      });
  });
};
