import {
  createSlice,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { GetEventsById } from "../../Services/events";

// Define the async thunk
export const GetCricketEvents = createAsyncThunk(
  "events/getCricketEvents",
  async () => {
    const response = await GetEventsById(4);
    return response; // assuming response is in the correct format
  }
);

export const GetOtherEvents = createAsyncThunk(
  "events/getCricketEventsw",
  async (id) => {
    const response = await GetEventsById(id);
    return response; // assuming response is in the correct format
  }
);

const CircketSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetCricketEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetOtherEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});
export default CircketSlice.reducer;
