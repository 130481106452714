import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import BetSlip from "../bottomnav/BetSlip";
import { getMatchOdds } from "../../Services/events";

const MatchOdds = ({ event }) => {
  const [isBetting, setIsBetting] = useState(false);
  const [MatchOdds, setMatchOdds] = useState();
  const [selectedBet, setSelectedBet] = useState();
  const [selectedevent, setSelectedevent] = useState();

  if (!isBetting) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }
  async function getODDs() {
    await getMatchOdds(event?.market?.id).then((res) => {
      setMatchOdds(res);
    });
  }
  useEffect(() => {
    getODDs();
  }, []);
  return (
    <div className="py-3">
      <div className="container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="d-flex align-items-center justify-content-between text-white ff_inter fw-medium fs_16">
              MATCH_ODDS <p className="mb-0 ms-auto">Max:1</p>
            </Accordion.Header>
            <Accordion.Body>
              {MatchOdds && (
                <div>
                  <div>
                    <p className="ff_heebo fs_15 mb-1 text-white">
                      {event?.runners[0]?.name}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.back[0]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center first-last-price-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.back[2]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.back[2]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.back[1]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center blue-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.back[1]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.back[1]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.back[2]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center blue-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.back[0]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.back[0]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.lay[0]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center pink-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.lay[0]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.lay[0]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.lay[1]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center pink-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.lay[1]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.lay[1]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[0]?.lay[2]?.price || 0
                          );
                          setSelectedevent(event?.runners[0]?.name);
                        }}
                        className="text-black text-center first-last-price-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[0]?.lay[2]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[0]?.lay[2]?.size || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="match-odds-hr-line my-3"></div>
                  <div>
                    <p className="ff_heebo fs_15 mb-1 text-white">
                      {event?.runners[1]?.name}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.back[0]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center first-last-price-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.back[2]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.back[2]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.back[1]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center blue-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.back[1]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.back[1]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.back[2]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center blue-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.back[0]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.back[0]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.lay[0]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center pink-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.lay[0]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.lay[0]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.lay[1]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center pink-clr-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.lay[1]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.lay[1]?.size || "-"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setIsBetting(true);
                          setSelectedBet(
                            MatchOdds[0]?.runners[1]?.lay[2]?.price || 0
                          );
                          setSelectedevent(event?.runners[1]?.name);
                        }}
                        className="text-black text-center first-last-price-div py-1 px-2"
                      >
                        <p className="mb-0 ff_heebo fw-bold fs_15">
                          {MatchOdds[0]?.runners[1]?.lay[2]?.price || "-"}
                        </p>
                        <p className="mb-0 ff_heebo fs_9">
                          {MatchOdds[0]?.runners[1]?.lay[2]?.size || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div
        onClick={() => setIsBetting(false)}
        className={`body-overlay ${isBetting === true ? "d-block" : "d-none"}`}
      ></div>
      <BetSlip
        active={isBetting}
        setIsBettingFalse={() => setIsBetting(false)}
        selectedBet={selectedBet}
        event={event}
        selecetedEvent={selectedevent}
      />
    </div>
  );
};

export default MatchOdds;
