import React, { useEffect, useState } from "react";
import speaker from "../../assets/images/svg/speaker.svg";
import { useDispatch, useSelector } from "react-redux";
import SearchModal from "../Modals/SearchModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from "react-router-dom";
import Skeletion from "../skeletion/Skeletion";
import { GetBanner } from "../../Services/Banner";
import { setLocation } from "../../redux/Location/DeviceLocationSlice";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import axios from "axios";
import { Modal } from "react-bootstrap";

const HomeHero = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const User = useSelector((state) => state.User.User);
  const [LoginModal, setLoginModal] = useState(false)
  const Country = useSelector((state) => state.Location.location);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [searchModal, setSearchModal] = useState(false);
  const [Banners, setBanners] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      getUserLocation(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      await dispatch(setLocation(response.data.country))
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };

  useEffect(() => {
    getUserIP();
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1.1,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  const getHomeBaner = async () => {
    const data = {
      type: "home", // ["sports", "casino", "home"]
      country: Country,
    };
    try {
      const res = await GetBanner(data);
      setBanners(res.data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Failed to fetch banners:", error);
    }
  };
  const getSportBaner = async () => {
    const data = {
      type: "sports", // ["sports", "casino", "home"]
      country: Country,
    };
    try {
      const res = await GetBanner(data);
      setBanners(res.data);
      setLoading(false)
    } catch (error) {
      console.error("Failed to fetch banners:", error);
      setLoading(false)
    }
  };
  const getCasinoBaner = async () => {
    const data = {
      type: "casino", // ["sports", "casino", "home"]
      country: Country,
    };
    try {
      const res = await GetBanner(data);
      setLoading(false)
      setBanners(res.data);
    } catch (error) {
      setLoading(false)
      console.error("Failed to fetch banners:", error);
    }
  };

  useEffect(() => {
    setLoading(true)
    if (Country) {
      switch (pathname) {
        case "/":
          getHomeBaner()
          break;
        case "/sports":
          getSportBaner()
          break;
        case "/casino-page":
          getCasinoBaner()
          break;
        default:
          break;
      }
    }
  }, [pathname, Country]);

  const handleBannerClick = (bannerType, url) => {
    if (bannerType === "sports") {
      navigate(`/banner/${url}`);
    } else {
      if(!User){
        setLoginModal(true)
        return
      }
      navigate(`/casino-player/${url}?fn=true/`);
    }
  };

  return (
    <div className="pt-4">
      <div className="container">
        <div className="bg_clr_dark_grey_3 p-2 rounded-2">
          <div className="d-flex align-items-center">
            <img style={{ width: "30px" }} src={speaker} alt="speaker" />
            <marquee className="w-100">
              <p className="mb-0 text-white ff_heebo fs_11">
                Experience the Thrill of Winning – Join 45X Today and start playing
              </p>
            </marquee>
          </div>
        </div>
        <div className="mt-3 position-relative mb-5">
          {Banners.length > 0 ? (
            <Slider {...settings}>
              {Banners.map((item, index) => (
                <img
                  key={index}
                  className="w-100 rounded-2"
                  src={`${baseUrl}/${item?.imageUrl}`}
                  height={150}
                  alt="banner"
                  onClick={() => handleBannerClick(item?.banner_type, item?.redirect_id)}
                />
              ))}
            </Slider>
          ) : (

            loading ?
              <Skeletion height={"200px"} borderRadius={5} />
              : ""

          )}
        </div>
      </div>
      {searchModal && <SearchModal open={searchModal} close={() => setSearchModal(false)} />}

      <Modal className="bg_modal" show={LoginModal} onHide={()=>{
        setLoginModal(false)
      }} centered>
        <Modal.Body className="text-center  text-white">
          <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div onClick={()=>{
              setLoginModal(false)
            }} className=" position-absolute end-0 top-0">
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
              You cannot play Without Login
              <br />
              <br />
              Please Login to procced
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeHero;
