import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { GetAllSportsCategory } from "../../Services/SportsRadar";

// Define the async thunk
export const GetAllSports = createAsyncThunk(
    "SportsRadar/getAllSports",
    async () => {
        const response = await GetAllSportsCategory();
        return response; // assuming response is in the correct format
    }
);

const SportsRadarSlice = createSlice({
    name: "SportsRadar",
    initialState: {
        allSports: [],
        events: [],
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetAllSports.fulfilled, (state, action) => {
            state.allSports = action.payload;
            state.loading = false;
            state.error = null;
        });
    },
});
export default SportsRadarSlice.reducer;
