import React, { useState } from "react";
import { Link } from "react-router-dom";
import football from "../../assets/images/png/football_image.png";
import Cricket from "../../assets/images/png/cricket_image.png";
import Basketball from "../../assets/images/png/basketball_image.png";

const sports = [
  { image: football, id: 1, name: "Cricket" },
  { image: Cricket, id: 2, name: "Football" },
  { image: Basketball, id: 3, name: "Basketball" },
  { image: football, id: 4, name: "Tennis" },
  { image: football, id: 5, name: "Volleyball" },
];

const IntrestDetails = () => {
  const [selectedSports, setSelectedSports] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedSports.includes(id)) {
      setSelectedSports(selectedSports.filter((sportId) => sportId !== id));
    } else {
      setSelectedSports([...selectedSports, id]);
    }
  };

  return (
    <div className="">
      <div className="container">
        <div className="d-flex flex-column min-vh-100 justify-content-between">
          <div className="pt-4">
            <h3 className="text-white fs_24 fw-bold ff_heebo">
              First up, which sport do you{" "}
              <span className="clr_green">enjoy the most?</span>
            </h3>
            <div className="pb-4">
              {sports.map((sport) => (
                <div
                  key={sport.id}
                  className={`d-flex justify-content-between p-3 ${
                    selectedSports.includes(sport.id)
                      ? "bg_clr_green"
                      : "bg_clr_dark_grey_3"
                  } mt-4 rounded-1 align-items-center`}
                  onClick={() => handleCheckboxChange(sport.id)}
                >
                  <div className="d-flex align-items-center">
                    <label className="text-white fs_16 ff_roboto">
                      <img width={61} src={sport.image} alt="" />
                    </label>
                    <label className="text-white ms-2 fs_16 ff_roboto">
                      {sport.name}
                    </label>
                  </div>
                  {/* <input
                    type="checkbox"
                    checked={selectedSports.includes(sport.id)}
                    readOnly
                  /> */}
                  <label class="check_box_input">
                    <input
                      checked={selectedSports.includes(sport.id)}
                      readOnly
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex pb-4 justify-content-between">
            <button className="px-5 py-2 bg-transparent border-0 fs_16 text-white ff_roboto">
              Skip
            </button>
            <Link to="/casino-page">
              <button className="fs_16 text-white bg_clr_green border-0 px-5 py-3 rounded-1 ff_roboto">
                Continue
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntrestDetails;
