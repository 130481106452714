import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import * as Ably from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react';
const client = new Ably.Realtime({ key: 'RgOc7Q.pbQGhw:ubMHJBT23PY5eYePuInb74lLzRK00VQ_tEcPQ3ingP0' });

ReactDOM.render(
  <Provider store={store}>
    <AblyProvider client={client}>
      <ChannelProvider channelName="get-started">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}>
          <App />
        </GoogleOAuthProvider>
      </ChannelProvider>
    </AblyProvider>
  </Provider>,
  document.getElementById("root")
);
