import React, { useEffect, useState } from "react";
import trfy_icon from "../../assets/images/svg/trofy_icon.svg";
import Results_ptee from "../../assets/images/svg/Result_ptee.svg";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import { getLiveGameUrl } from "../../Services/Casino";
import { useSelector } from "react-redux";
import backIcon from "../../assets/images/png/left.png"
const CasinoPlayer = () => {
  const [showModal, setShowModal] = useState(false);
  const [GameUrl, setGameUrl] = useState(null);
  const [GameMode, setGameModae] = useState(null);
  const UserBalance = useSelector((state) => state.User.balance);
  const User = useSelector((state) => state.User.User);
  const { id } = useParams();
  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShowModal(false);
  };

  function getQueryParam(url) {
    let urlObj = new URL(url);
    return urlObj.searchParams.get("fn");
  }
  useEffect(() => {
    const res = getQueryParam(window.location.href);
    setGameModae(res);
  }, []);

  useEffect(() => {
    if (GameMode !== null) {
      if (User !== null) {
        getLiveGameUrl(id, User?._id, false).then((res) => {
          setGameUrl(res.data.game_url);
        });
      } else {
        getLiveGameUrl(id, User?._id, GameMode).then((res) => {
          setGameUrl(res.data.game_url);
        });
      }
    }
  }, [GameMode]);

  useEffect(() => {
    const intercom = document.getElementsByClassName("intercom-lightweight-app-launcher");
    for (let i = 0; i < intercom.length; i++) {
      intercom[i].style.display = "none";
    }
  }, []);
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div>
      {GameUrl !== null ? (
        <div>
          <div className="text-center text-white py-3">
            Game is Loading , Please Wait
          </div>
          <div
            className="skeleton rounded gameLoading"
            style={{
              height: "50vh",
              width: "80vw",
              margin: "auto",
            }}
          ></div>
          <div className="d-flex flex-column justify-content-center w-100 align-items-center position-absolute z-100 top-0">
            <div className="container">
              <div className="d-flex w-100 py-2 bg_clr_dark_blue_2 justify-content-between align-items-center">
                <div className="bg_clr_green p-2 rounded d-flex justify-content-center align-items-center">
                  <img src={backIcon} width={20} height={20} onClick={() => {
                    navigate("/casino-page")
                  }} />
                </div>
                <div><div className="price_container p-1 rounded d-flex align-items-center">
                  <div className="d-flex flex-column ps-2 pe-3 ">
                    <span className="currency ff_heebo fw-medium fs_12 text-uppercase">
                      inr
                    </span>
                    <span className="text-white ff_heebo fw-medium fs_12">
                      {Math.round(Number(UserBalance || 0)).toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <button
                      style={{ borderRadius: "2px" }}
                      className="text-white ff_heebo fw-medium fs_12 bg_clr_green px-3 py-2 border-0 d-flex"
                      onClick={() => {
                        navigate("/deposit");
                      }}
                    >
                      Deposit
                    </button>
                  </div>
                </div></div>
              </div>
            </div>
            <iframe src={GameUrl} className="w-100 vh-90">
              Your browser does not support iframes.
            </iframe>
          </div>
        </div>
      ) : (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="success" size="md" />
        </div>
      )}
      <Modal
        className="bg_modal"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Body className="text-center  text-white">
          <h4>Results</h4>
          <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={handleCloseModal}
              className=" position-absolute end-0 top-0"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <img src={trfy_icon} alt="trfy_icon" />
            <h3 className=" fs_28 fw-medium ff_heebo text-white mt-3 mb-0">
              Player A
            </h3>
            <img className="mt-3" src={Results_ptee} alt="Results_ptee" />
          </div>
          <h3 className="fs_28 fw-medium ff_heebo text-white mt-3 mb-0 mt-4">
            Player B
          </h3>
          <img className="mt-3" src={Results_ptee} alt="Results_ptee" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CasinoPlayer;
