import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import minus from "../../assets/images/svg/minus.svg";
import BetSlip from "../bottomnav/BetSlip";
import { getBookMaker, getBookMakerby } from "../../Services/events";

const BookMaker = ({ event }) => {
  const [isBetting, setIsBetting] = useState(false);
  const [getBookMakerEvent, setBookMarkerEvnets] = useState(null);
  const [BookMaker, setBookMaker] = useState([]);
  if (!isBetting) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }
  useEffect(() => {
    getBookMaker(event?.event?.id).then((res) => {
      setBookMarkerEvnets(res);
    });
  }, [event]);

  useEffect(() => {
    const len = getBookMakerEvent?.length >= 2 ? 1 : 0;
    if (getBookMakerEvent != null) {
      getBookMakerby(getBookMakerEvent[len]?.market?.id).then((res) => {
        setBookMaker(res);
      });
    }
  }, [getBookMakerEvent]);
  return (
    <div className="py-3">
      <div className="container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="d-flex align-items-center justify-content-between text-white ff_inter fw-medium fs_16">
              Bookmaker <p className="mb-0 ms-auto">Min:100 Max:5L</p>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <div>
                  <p className="ff_heebo fs_15 mb-1 text-white">
                    {event?.runners[0]?.name}
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <div className="text-black text-center blue-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[0]?.back[0]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[0]?.back[0]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div
                      onClick={() => setIsBetting(true)}
                      className="text-black text-center blue-clr-div py-1 px-2"
                    >
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[0]?.back[1]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[0]?.back[1]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div
                      onClick={() => setIsBetting(true)}
                      className="text-black text-center pink-clr-div py-1 px-2"
                    >
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[0]?.lay[0]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[0]?.lay[0]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div className="text-black text-center pink-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[0]?.lay[1]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[0]?.lay[1]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                  </div>
                </div>
                <div className="match-odds-hr-line my-3"></div>
                <div>
                  <p className="ff_heebo fs_15 mb-1 text-white">
                    {event?.runners[1]?.name}
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <div className="text-black text-center blue-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[1]?.back[0]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[1]?.back[0]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div className="text-black text-center blue-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[1]?.back[1]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[1]?.back[1]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div className="text-black text-center pink-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[1]?.lay[0]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[1]?.lay[0]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                    <div className="text-black text-center pink-clr-div py-1 px-2 d-flex align-items-center justify-content-center">
                      {(
                        <div>
                          <p className="mb-0 ff_heebo fw-bold fs_15">
                            {BookMaker[0]?.runners[1]?.lay[1]?.price}
                          </p>
                          <p className="mb-0 ff_heebo fs_9">
                            {BookMaker[0]?.runners[1]?.lay[1]?.size}
                          </p>
                        </div>
                      ) || <img className="w-50" src={minus} alt="minus" />}
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div
        onClick={() => setIsBetting(false)}
        className={`body-overlay ${isBetting === true ? "d-block" : "d-none"}`}
      ></div>
      <BetSlip
        active={isBetting}
        setIsBettingFalse={() => setIsBetting(false)}
      />
    </div>
  );
};

export default BookMaker;
