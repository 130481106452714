import React, { useEffect, useState } from "react";
import { GetAllDepositRequest, GetAllWidtharalRequest } from "../../Services/Transactions";
import { getLocalDate, getLocalTime } from "../../Services/Common";
import { Modal } from "react-bootstrap";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import eye from "../../assets/images/png/eye.png"
function Transactions() {
  const [activeTab, setActiveTab] = useState("deposit"); // Initial tab
  const [depositHistory, setDepositHistory] = useState([]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const baseurl = process.env.REACT_APP_BASEURL;
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getDepositHistory = async () => {
    const data = {
      payment_status: "", // ["pending", "completed", "canceled", "rejected"]
      start_date: "",
      end_date: "",
      page: 1,
      limit: 10,
    };
    await GetAllDepositRequest(data).then((res) => {
      setDepositHistory(res?.data);
    });
  };

  const getWithdrawalHistory = async () => {
    const data = {
      payment_status: "", // ["pending", "completed", "canceled", "rejected"]
      start_date: "",
      end_date: "",
      page: 1,
      limit: 10,
    };
    await GetAllWidtharalRequest(data).then((res) => {
      setWithdrawalHistory(res?.data);
    });
  };

  useEffect(() => {
    switch (activeTab) {
      case "deposit":
        getDepositHistory();
        break;
      case "withdrawal":
        getWithdrawalHistory();
        break;
      default:
        break;
    }
  }, [activeTab]);

  const openModal = (item) => {
    setShowImage(true);
    setSelectedImage(item?.screenshot);
  };

  return (
    <div className="container py-3">
      <div>
        <h5 className="fs_16 fw-medium ff_heebo text-white">Transactions</h5>

        <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
          <button
            className={`py-2 ff_heebo ${activeTab === "deposit"
              ? "clr_green bg_clr_green green_border text-white"
              : "bg-transparent green_border clr_green"
              } rounded-1 w-100`}
            onClick={() => handleTabClick("deposit")}
          >
            Deposit
          </button>
          <button
            className={`py-2 ff_heebo ${activeTab === "withdrawal"
              ? "clr_green bg_clr_green green_border text-white"
              : "bg-transparent green_border clr_green"
              } rounded-1 w-100`}
            onClick={() => handleTabClick("withdrawal")}
          >
            Withdrawal
          </button>
        </div>

        {activeTab === "deposit" ? (
          <div>
            {depositHistory.length > 0 ? (
              depositHistory.map((item, index) => (
                <div
                  key={index}
                  className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    if (item?.payment_method !== "bonus") {
                      openModal(item);
                    }
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <span className="fs_16 fw-medium ff_heebo ">
                        {item?.amount}
                      </span>
                      <span className="fs_12 fw-medium ff_heebo ">
                        ({item.payment_method})
                      </span>
                      {item?.payment_method !== "bonus" && <img src={eye} width={15} />}
                    </div>
                    <span className="fs_10 fw-medium ff_heebo text-white">
                      {getLocalDate(item.createdAt) + " " + `(${getLocalTime(item.createdAt)})`}
                    </span>
                  </div>
                  <div className={`fs_14 fw-medium ff_heebo text-capitalize ${item?.status === "completed" ? "clr_green" : item?.status === "rejected" ? "clr_red" : "clr_yellow"}`}>
                    {item?.status}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">No Deposit Found</h1>
              </div>
            )}
          </div>
        ) : (
          <div>
            {withdrawalHistory.length > 0 ? (
              withdrawalHistory.map((item, index) => (
                <div
                  key={index}
                  className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex flex-column">
                    <span className="fs_16 fw-medium ff_heebo ">
                      {item.amount}
                    </span>
                    <span className="fs_10 fw-medium ff_heebo text-white">
                      {getLocalDate(item.createdAt) + " " + `(${getLocalTime(item.createdAt)})`}
                    </span>
                  </div>
                  <div className={`fs_14 fw-medium ff_heebo text-capitalize ${item?.status === "completed" ? "clr_green" : item?.status === "rejected" ? "clr_red" : "clr_yellow"}`}>
                    {item?.status}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">No Withdrawal Found</h1>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal className="bg_modal" show={showImage} onHide={() => setShowImage(false)} centered>
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div onClick={() => setShowImage(false)} className="position-absolute end-0 top-0">
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <div>
              <img src={`${baseurl}/image/${selectedImage}`} className="w-100" alt="Transaction Screenshot" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Transactions;
