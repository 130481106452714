import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { LoginWithEmail } from "../../Services/AuthService";
import { useDispatch } from "react-redux";
import { setEmail } from "../../redux/User/UserSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { countryList } from "../../Services/Common";

const MailLogin = () => {
  const [value, setValue] = useState({ email: "", country: "", currency: "" });
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
    setErrors({ ...errors, checkbox: "" });
  };

  const validate = () => {
    const newErrors = {};
    if (!value.email) newErrors.email = "Email is required";
    if (!value.country) newErrors.country = "Country is required";
    if (!value.currency) newErrors.currency = "Currency is required";
    if (!checkboxChecked)
      newErrors.checkbox = "You must agree to the terms and conditions";
    return newErrors;
  };

  const handleLogin = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      await LoginWithEmail(value).then((res) => {
        if (res) {
          dispatch(setEmail(value.email));
          navigate("/otp/verifi/email");
        } else {
          toast.error("error in sending OTP");
        }
      });
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="d-flex justify-content-between flex-column">
        <div>
          <div className="pt-4 w-100 d-flex flex-column">
            <label className="clr_grey ff_roboto" htmlFor="email">
              Email <span className="clr_red">*</span>
            </label>
            <input
              placeholder="Enter Email"
              className="bg_clr_dark_grey_3 clr_grey border-0 mt-3 rounded-1 py-3 px-3"
              type="email"
              name="email"
              value={value.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
          <div className="pt-4 w-100 d-flex flex-column">
            <label className="clr_grey ff_roboto" htmlFor="country">
              Choose Country <span className="clr_red">*</span>
            </label>
            <Form.Select
              size="md"
              className="bg_clr_dark_grey_3 ff_roboto clr_grey border-0 mt-3 p-3"
              name="country"
              value={value.country}
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {countryList.map((item, index) => {
                return (
                  <option value={item.code}>{item.name}</option>
                )
              })}
            </Form.Select>
            {errors.country && (
              <span className="text-danger">{errors.country}</span>
            )}
          </div>
          <div className="pt-4 w-100 d-flex flex-column">
            <label className="clr_grey ff_roboto" htmlFor="currency">
              Choose Currency <span className="clr_red">*</span>
            </label>
            <Form.Select
              size="md"
              className="bg_clr_dark_grey_3 ff_roboto clr_grey border-0 mt-3 p-3"
              name="currency"
              value={value.currency}
              onChange={handleChange}
            >
              <option value="">Select Currency</option>
              <option>R$-Brazilian real (BRL)</option>
            </Form.Select>
            {errors.currency && (
              <span className="text-danger">{errors.currency}</span>
            )}
          </div>
          <p className="fs_12 clr_grey mt-3 ff_roboto">
            The currency you select will affect which payment methods you can
            use to make deposits and withdrawals. After you've registered, you
            will not be able to change your account currency.
          </p>
        </div>
        <div className="mt-4">
          <div className="d-flex gap-3 align-items-start">
            <input
              className="mt-2"
              id="check"
              type="checkbox"
              checked={checkboxChecked}
              onChange={handleCheckboxChange}
            />
            <label className="fs_12 text-white ff_roboto" htmlFor="check">
              I agree with User Agreements, Terms & Conditions and confirm that
              I am over 18 years old
            </label>
          </div>
          {errors.checkbox && (
            <span className="text-danger">{errors.checkbox}</span>
          )}
          <button
            className="w-100 mt-4 fs_16 ff_roboto text-white bg_clr_green border-0 p-3 rounded-1"
            onClick={handleLogin}
          >
            Continue
          </button>
          <button className="w-100 mt-4 fw-bold ff_roboto fs_16 text-white border-0 bg_clr_dark_grey_3 p-3 rounded-1">
            <span className="fw-normal clr_green">Log in with</span> Demo ID
          </button>
        </div>
      </div>
    </div>
  );
};

export default MailLogin;
