import { RouterProvider } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./Routes/routes";
import { useEffect, useState } from "react";
import socketService from "./Services/SocketService";
import { useDispatch, useSelector } from "react-redux";
import { SaveUser } from "./Services/AuthService";
import { Modal } from "react-bootstrap";
import { setBalance } from "./redux/User/UserSlice";
function App() {
  const User = useSelector((state) => state.User.User);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch()
  const [logoutTimeout, setLogoutTimeout] = useState(3000); // New state variable for timeout duration

  async function getMachineId() {
    let machineId = await localStorage.getItem('MachineId');
    if (!machineId) {
      machineId = await crypto.randomUUID();
      await localStorage.setItem('MachineId', machineId);
    }
    return machineId;
  }  

  useEffect(() => {
    if (User) {
      getMachineId();
    }
  }, [User]);

  const SetUserBalance = (balance) => {
    dispatch(setBalance(balance))
  }

  useEffect(() => {
    getMachineId();
    if (User) {
      // Connect to the socket server
      let machineId = localStorage.getItem('MachineId');
      socketService.connect();
      // Listen for specific events
      socketService.on('someEvent', handleSomeEvent);
      console.log(User?._id)
      socketService.emit('join', { userId: User._id },
        (error, message) => {
          if (error) {
            console.log(`Error joining: ${error}`);
          } else {
            console.log(message);
          }
        }
      );
      socketService.emit('join', { userId: machineId },
        (error, message) => {
          if (error) {
            console.log(`Error joining: ${error}`);
          } else {
            console.log(message);
          }
        }
      );
      socketService.on('balance', (data) => {
        console.log("balace", data)
        SetUserBalance(data?.balance)
      });
      socketService.on('logout_request', () => {
        setShow(true);
        setTimeout(() => {
          localStorage.clear();
          setShow(false);
          window.location.reload();
        }, logoutTimeout); // Use the state variable for timeout duration
      });

      // Clean up on component unmount
      // return () => {
      //   socketService.off('someEvent', handleSomeEvent);
      //   socketService.disconnect();
      // };
    }
  }, [socketService, User, logoutTimeout]);

  const handleSomeEvent = (data) => {
    console.log('Received someEvent: ', data);
  };

  useEffect(() => {
    if (User) {
      getMachineId();
      let machineId = localStorage.getItem('MachineId');
      const data = {
        userId: User?._id,
        device_id: machineId
      };
      SaveUser(data).then(res => {
        console.log(res);
      });
    }
  }, [User]);

  return (
    <div className="pb-5 mb-4">
      <RouterProvider router={router} />
      <Modal className="bg_modal" show={show} backdrop="static" centered>
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <h3 className="fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
              Login detected to other devices. Your ID will be logged out in {logoutTimeout / 1000} seconds.
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
