import axios from "axios";
const Url = process.env.REACT_APP_BASEURL;


export const GetAllSportsCategory = () => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRedarSports" })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GetSideBarData = () => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRedarSportsSidebar" })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GetSportById = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRedarSportsMatch", method: "POST", data })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const SelectedSport = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getRedarSportsMatchEvent", method: "POST", data })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const getFancy = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/GetMarketbyEeventID", method: "POST", data })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBookMaker = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getBookmaker", method: "POST", data })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFancyOdds = (data) => {
  return new Promise((resolve, reject) => {
    axios({ url: Url + "/getFancyOdsDataByIds", method: "POST", data })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
