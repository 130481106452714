import React, { useEffect } from "react";
import SupportHero from "../components/supportpage/SupportHero";
import AppNavbar from "../components/home/AppNavbar";
import BottomNav from "../components/bottomnav/BottomNav";

const SupportPage = () => {
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div>
      <SupportHero />
    </div>
  );
};

export default SupportPage;
