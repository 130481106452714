import React, { useState } from "react";
import mi from "../../assets/images/png/mumbaiIndians.png";
import dc from "../../assets/images/png/delhiCapitals.png";
import liveBrocast from "../../assets/images/png/liveBroadcast.png";
import liveScrore from "../../assets/images/svg/liveScore.svg";
import tv from "../../assets/images/svg/tv.svg";
import "./Betting.css";
import { GetDateDescription, TimePending } from "../../Services/Common";
import ScoreBoard from "../ScoreBoard/ScoreBoard";

const EventsInfo = ({ event }) => {
  const [selectedInfo, setSelectedInfo] = useState(0);
  return (
    <div>
      <div className="container">
        <div
          style={{ height: "440px" ,overflow : "hidden" }}
          className="matches-div rounded px-3 py-4 mt-3 mb-5"
        >
          <div className="d-flex align-items-center justify-content-between score-broadcast-div rounded">
            <button
              onClick={() => setSelectedInfo(0)}
              className={`d-flex align-items-center justify-content-center gap-1 ff_inter fw-medium fs_14 text-white h-100 w-100 border-0 ${
                selectedInfo === 0
                  ? "bg_clr_green rounded-start"
                  : "bg-transparent"
              }`}
            >
              <img src={liveScrore} alt="score" />
              Live Score
            </button>
            <button
              onClick={() => setSelectedInfo(1)}
              className={`d-flex align-items-center justify-content-center gap-1 ff_inter fw-medium fs_14 text-white h-100 w-100 border-0 ${
                selectedInfo === 1
                  ? "bg_clr_green rounded-end"
                  : "bg-transparent"
              }`}
            >
              <img src={tv} alt="score" />
              Live Broadcast
            </button>
          </div>
          {selectedInfo === 0 ? (
            <div>
              <div className="py-4"  style={{ height: "349px" ,overflow : "hidden" }}>
              <ScoreBoard/>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <img className="w-100" src={liveBrocast} alt="liveBrocast" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsInfo;
