import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import LoginHero from "../components/auth/LoginHero";
const LoginTemplate = () => {
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div className="bg_clr_dark_blue_2 min-vh-100 py-4">
      <div className="container">
        <div>
          <LoginHero />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default LoginTemplate;
