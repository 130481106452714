import React, { useEffect } from "react";
import chatIcon from "../../assets/images/svg/chet_icon.svg";
import uploadIcon from "../../assets/images/svg/uploadIcon.svg";
import chatEntericon from "../../assets/images/svg/chatEnterIcon.svg";
import BottomNav from "../bottomnav/BottomNav";
import Intercom from '@intercom/messenger-js-sdk';

const SupportChat = () => {
  useEffect(() => {
    console.log("Initializing Intercom...");
    Intercom({
      app_id: 'xm142uyz' // Replace with your actual Intercom app ID
    });

    Intercom('onShow', () => {
      console.log('Intercom messenger is shown');
    });

    Intercom('onHide', () => {
      console.log('Intercom messenger is hidden');
    });

    Intercom('onUnreadCountChange', (unreadCount) => {
      console.log(`Unread message count: ${unreadCount}`);
    });

    Intercom('onBoot', () => {
      console.log('Intercom has booted successfully');
    });

    Intercom('onReady', () => {
      console.log('Intercom is ready');
      Intercom('show'); // Make sure Intercom is shown when it's ready
    });

    return () => {
      console.log("Shutting down Intercom...");
      Intercom('shutdown');
    };
  }, []);

  return (
    <div className="position-relative">
      <div className="d-flex position-fixed bg_clr_dark_blue_2 px-3 w-100 top-0 p-3 align-items-center gap-2">
        <img src={chatIcon} alt="chatIcon" />
        <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white pt-1">
          Support Chat
        </h5>
      </div>
      <div className="container px-3">
        <div className="pb-5">
          <div className="overflow-y-auto py-5 mt-3 h-100">
            <div className="row justify-content-end mt-3">
              <div className="col-10">
                <div className="bg_clr_dark_grey_3 p-3 rounded-2">
                  <p className="fs_16 ff_heebo text-white mb-0">
                    Hi, I'm having trouble placing a bet on the app.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-start mt-3">
              <div className="col-10">
                <div className="bg_clr_green p-3 rounded-2">
                  <p className="fs_16 ff_heebo text-white mb-0">
                    Hello! I'm sorry to hear that you're experiencing difficulties. Could you please provide me with more details about the issue you're facing?
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-end mt-3">
              <div className="col-10">
                <div className="bg_clr_dark_grey_3 p-3 rounded-2">
                  <p className="fs_16 ff_heebo text-white mb-0">
                    Sure, every time I try to place a bet, I get an error message saying "Bet not placed, please try again later."
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-start mt-3">
              <div className="col-10">
                <div className="bg_clr_green p-3 rounded-2">
                  <p className="fs_16 ff_heebo text-white mb-0">
                    I'm sorry for the inconvenience. Let me check this for you. Your Ticket Number: #9298383
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ps-3 py-3 bg_clr_dark_blue_2 gap-3 pe-4 justify-content-between w-100 position-fixed bottom-0">
        <div className="w-100 px-3 d-flex clr_grey_2 border border-1 border-white rounded-5 bg-transparent">
          <input
            className="border-0 rounded-5 bg-transparent outline_none clr_grey_2 w-100"
            type="text"
            placeholder="Type a Message..."
          />
          <img src={chatEntericon} alt="chatEntericon" width={30}/>
        </div>
        <button className="me-2 bg_clr_dark_grey_3 p-3 rounded-5 border-0">
          <img src={uploadIcon} alt="uploadIcon" />
        </button>
      </div>
    </div>
  );
};

export default SupportChat;
