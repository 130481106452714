import React from "react";
import { Modal } from "react-bootstrap";
import cross_icon from "../../assets/images/svg/cross_icon.svg";

function BlockedModal({ open, close }) {
  return (
    <div>
      <Modal className="bg_modal" show={open} onHide={close} centered>
        <Modal.Body className="text-center  text-white">
          <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div onClick={close} className=" position-absolute end-0 top-0">
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
              Your account is blocked by admin 
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BlockedModal;
