import React from "react";

const WelcomeBonus = () => {
  return (
    <div>
      <div className="container">
        {" "}
        <div className="welcome_card rounded-3 p-3">
          <h3 className="text-center text-orange">WELCOME BONUS</h3>
          <h2 className=" fs_48 text-center color_green ff_inter fw-900">
            +100%
          </h2>
          <p className=" fs_22 fw-bold text-white ff_heebo text-center">
            WELCOME BONUS
          </p>
          <button className="get_btn py-3 text-white ff_heebo  w-100 border-0 rounded-2">
            Get it Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBonus;
