import React from "react";
import { Link } from "react-router-dom";

const Intrest = () => {
  return (
    <div className="intrest_bg_image">
      <div className="container d-flex flex-column min-vh-100">
        <div className="flex-grow-1 d-flex flex-column justify-content-center">
          <h1 className="text-white fs_36 fw-bold ff_heebo mb-0">
            Give us
            <br /> a Chance to
            <br />
            <span className="clr_green">
              Personalize
              <br /> your journey.
            </span>
          </h1>
        </div>
        <div className="d-flex justify-content-between pb-5">
          <button className="px-5 py-2 bg-transparent border-0 fs_16 text-white ff_roboto">
            Skip
          </button>
          <Link to="/intrest-details">
            <button className="fs_16 text-white bg_clr_green border-0 px-5 py-3 rounded-1 ff_roboto">
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intrest;
