import React, { useState } from 'react';
import * as Ably from 'ably';
import { AblyProvider, ChannelProvider, useChannel, useConnectionStateListener } from 'ably/react';

// Connect to Ably using the AblyProvider component and your API key
const client = new Ably.Realtime({ key: 'RgOc7Q.pbQGhw:ubMHJBT23PY5eYePuInb74lLzRK00VQ_tEcPQ3ingP0' });

export default function Try() {
    return (
        <AblyProvider client={client}>
            <ChannelProvider channelName="get-started">
                <AblyPubSub />
            </ChannelProvider>
        </AblyProvider>
    );
}

function AblyPubSub() {
    const [messages, setMessages] = useState([]);

    useConnectionStateListener('connected', () => {
        console.log('Connected to Ably!');
    });

    // Create a channel called 'get-started' and subscribe to all messages with the name 'first' using the useChannel hook
    const { channel } = useChannel('get-started', 'first', (message) => {
        setMessages(message.data);
    });

    return (
        // Publish a message with the name 'first' and the contents 'Here is my first message!' when the 'Publish' button is clicked
        <div>
            <button onClick={() => { channel.publish('first', 'Here is my first message!') }}>
                Publish
            </button>
            {
                messages.map(message => {
                    return <p key={message.id} className='text-white'>{message.data}</p>
                })
            }
        </div>
    );
}