import React, { useState } from "react";
import AppNavbar from "../home/AppNavbar";
import LiveCasinoIcon from "../../assets/images/svg/LiveCasinoIcon.svg";
import LiveCasinoimage1 from "../../assets/images/svg/LiveCasinoimage1.svg";
import LiveCasinoimage2 from "../../assets/images/svg/LiveCasinoimage2.svg";
import dot_icon from "../../assets/images/svg/dot_icon.svg";
import arrowRight from "../../assets/images/svg/arrowRight.svg";
import { Link } from "react-router-dom";

const ViewAllLiveCasino = () => {
  const [sports, setSports] = useState([
    {
      img: LiveCasinoimage1,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage1,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage1,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage1,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage1,
      sports: "364 playing",
    },
    {
      img: LiveCasinoimage2,
      sports: "364 playing",
    },
  ]);
  window.scrollTo(0, 0);
  return (
    <div className="">
      <div className="container pt-2">
        <div className="d-flex align-items-center justify-content-between mt-4 gap-3">
          <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
            Deposit
          </button>
          <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
            Withdrawal
          </button>
        </div>
        <div className=" mt-4">
          <div className="d-flex align-items-center gap-2">
            <img src={LiveCasinoIcon} alt="LiveCasinoIcon" />
            <p className="text-white mb-0 ff_inter fw-medium fs_16">
              Live Casino
            </p>
          </div>
        </div>
        <div className="row pt-4">
          {sports.map((value, index) => (
            <div
              key={index}
              className="col-4 rounded-2 d-flex flex-column align-items-center justify-content-center"
            >
              <Link to="/casino-player">
                <img className="w-100" src={value.img} alt={value.sports} />
                <div className="d-flex align-items-center gap-2 pb-4 pt-1">
                  <img src={dot_icon} alt="dot_icon" />
                  <p className="mb-0 ff_inter text-center fw-medium fs_10 text-white mt-1">
                    {value.sports}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewAllLiveCasino;
