import React, { useEffect, useState } from "react";
import AppNavbar from "../home/AppNavbar";
import notFoundIcon from "../../assets/images/svg/notFound_icon.svg";
import cross_icon from "../../assets/images/svg/cross_icon.svg";

import { Link } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import {
  AddBank,
  GetBank,
  WidthDrawlRequest,
} from "../../Services/PaymentService";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const Withdrawal = () => {
  const [bankDetails, setBankDetails] = useState({
    customer: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
  });
  const User = useSelector((state) => state.User.User);
  const [price, setPrice] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [addBankModal, setAddBankModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [bankList, setBankList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accountNumber" && value && !/^\d+$/.test(value)) {
      return;
    }
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.customer = bankDetails.customer
      ? ""
      : "Customer Name is required.";
    tempErrors.bankName = bankDetails.bankName ? "" : "Bank Name is required.";
    tempErrors.accountNumber = bankDetails.accountNumber
      ? ""
      : "Account Number is required.";
    tempErrors.ifscCode = bankDetails.ifscCode ? "" : "IFSC code is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleGetBanks = async () => {
    await GetBank().then((res) => {
      console.log(res);
      setBankList(res);
    });
  };

  const handleCreate = async () => {
    if (validate()) {
      console.log(bankDetails);
      const data = {
        customer_name: bankDetails.customer,
        account_number: bankDetails.accountNumber,
        ifsc_code: bankDetails.ifscCode,
        bank_name: bankDetails.bankName,
      };

      await AddBank(data).then((res) => {
        if (res) {
          toast.success("Bank added successfully");
          setBankDetails({
            customer: "",
            bankName: "",
            accountNumber: "",
            ifscCode: "",
          })
          setAddBankModal(false);
          handleGetBanks(); // Refresh the bank list after adding a new bank
        } else {
          toast.error("Error in adding bank");
        }
      });
    }
  };

  const handleCreateWirawlRequest = async () => {
    if (!price) {
      toast.error("Please enter an amount.");
      return;
    }

    if (price < 0) {
      toast.error("Please enter valid amount.");
      return;
    }
    if (price == 0) {
      toast.error("amount can't be zero");
      return;
    }

    if (!selectedBank) {
      toast.error("Please select a bank.");
      return;
    }
    if (price > User.balance) {
      toast.error("Insufficient Balance");
      return;
    }
    const data = {
      amount: price,
      bank_id: selectedBank,
    };
    WidthDrawlRequest(data).then((res) => {
      if (res) {
        toast.success("Withdrawal request created");
      } else {
        toast.error("Error in creating Withdrawal request");
      }
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 1048576) { // 1MB = 1048576 bytes
      toast.error("Image size should not be greater than 1MB.");
      return;
    }
    // Handle image upload logic here
  };

  useEffect(() => {
    handleGetBanks();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="container pb-4">
        <div className="d-flex align-items-center justify-content-between mt-4 gap-3">
          <Link className="w-100" to={"/deposit"}>
            <button
              style={{ height: "55px" }}
              className="ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100"
            >
              Deposit
            </button>
          </Link>
          <Link className="w-100" to={"/withdrawal"}>
            <button
              style={{ height: "55px" }}
              className="ff_heebo text-white bg_clr_green rounded-1 border-0 w-100"
            >
              Withdrawal
            </button>
          </Link>
        </div>
        <div className="d-flex flex-column mt-3">
          <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
            Enter Amount
          </label>
          <input
            className="mt-2 p-3 bg-transparent border border-1 rounded-1 outline_none text-white ff_heebo fw-medium fs_16 withdraw"
            type="number"
            value={price}
            onChange={(e) => {
              if (/^\d*$/.test(e.target.value)) {
                setPrice(e.target.value);
              }
            }}
            placeholder="50"
            min="0"
          />
        </div>
        {bankList.length > 0 && (
          <div className="d-flex flex-column mt-3">
            <label className=" fs_16 ff_heebo fw-normal text-white" htmlFor="">
              Select Bank
            </label>
            <select
              className="mt-2 p-3 bg_clr_dark_blue_2 border border-1 rounded-1 outline_none text-white ff_heebo fs_16"
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              <option value="">Select bank</option>
              {bankList.map((item, index) => {
                return (
                  <option
                    value={item?._id}
                    key={index}
                    className="bg_clr_dark_grey_2 border border-1 rounded-1 outline_none text-white ff_heebo fs_16"
                  >
                    {item.bank_name} ({item.account_number})
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <button
          className="bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_roboto fs_16 w-100 mt-5"
          onClick={handleCreateWirawlRequest}
        >
          Submit
        </button>
        <div className="mt-4 d-flex align-items-center bg_clr_dark_grey_3 justify-content-between rounded-1 p-3">
          <p className="mb-0 ff_heebo fw-bold fs_18 text-white">
            Bank Transfer
          </p>
          <button
            className="bg_clr_green rounded-1 text-white border-0 py-2 px-3 ff_roboto fs_16"
            onClick={() => {
              setAddBankModal(true);
            }}
          >
            Add New
          </button>
        </div>
        {bankList.length > 0 ? (
          <>
            {bankList.map((item, index) => {
              return (
                <div className="mt-4  align-items-center bg_clr_dark_grey_3 justify-content-between rounded-1 p-3">
                  <Row>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      Customer Name :
                    </Col>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      {item?.customer_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      Bank Name :
                    </Col>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      {item?.bank_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      Account Number :
                    </Col>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      {item?.account_number}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="ff_heebo  fs_18 text-white">
                      IFSC Code :
                    </Col>
                    <Col xs={6} className="ff_heebo fw-bold fs_18 text-white">
                      {item?.ifsc_code}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        ) : (
          <div className="text-center pt-4">
            <img src={notFoundIcon} alt="" />
          </div>
        )}
      </div>

      {/* Add Bank Modal */}
      <Modal
        show={addBankModal}
        onHide={() => setAddBankModal(false)}
        centered
      >
        <Modal.Body className="bg_clr_dark_blue_2 p-4 position-relative rounded-1">
          <img
            src={cross_icon}
            alt=""
            className="position-absolute cross-btn end-0 top-0 pt-2 pe-2"
            onClick={() => setAddBankModal(false)}
          />
          <div className="d-flex flex-column mt-3">
            <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
              Customer Name
            </label>
            <input
              className="mt-2 p-3 bg-transparent border border-1 rounded-1 outline_none text-white ff_heebo fw-medium fs_16 withdraw"
              type="text"
              name="customer"
              value={bankDetails.customer}
              onChange={handleChange}
              placeholder="Enter Customer Name"
            />
            {errors.customer && (
              <span className="text-danger">{errors.customer}</span>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
              Bank Name
            </label>
            <input
              className="mt-2 p-3 bg-transparent border border-1 rounded-1 outline_none text-white ff_heebo fw-medium fs_16 withdraw"
              type="text"
              name="bankName"
              value={bankDetails.bankName}
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
            {errors.bankName && (
              <span className="text-danger">{errors.bankName}</span>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
              Account Number
            </label>
            <input
              className="mt-2 p-3 bg-transparent border border-1 rounded-1 outline_none text-white ff_heebo fw-medium fs_16 withdraw"
              type="text"
              name="accountNumber"
              value={bankDetails.accountNumber}
              onChange={handleChange}
              placeholder="Enter Account Number"
            />
            {errors.accountNumber && (
              <span className="text-danger">{errors.accountNumber}</span>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="fs_16 ff_heebo fw-normal text-white" htmlFor="">
              IFSC Code
            </label>
            <input
              className="mt-2 p-3 bg-transparent border border-1 rounded-1 outline_none text-white ff_heebo fw-medium fs_16 withdraw"
              type="text"
              name="ifscCode"
              value={bankDetails.ifscCode}
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
            {errors.ifscCode && (
              <span className="text-danger">{errors.ifscCode}</span>
            )}
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-end">
            <button
              className="bg_clr_green py-3 rounded-1 border-0 text-white fw-medium ff_roboto fs_16 w-100 mt-5"
              onClick={handleCreate}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Withdrawal;
