import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import BetSlip from "../bottomnav/BetSlip";
import { getFancyById, getFancyCount } from "../../Services/events";

const Fancy = ({ event }) => {
  const [isBetting, setIsBetting] = useState(false);
  const [ids, setIds] = useState("");
  const [fancy, setFancy] = useState([]);
  if (!isBetting) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }
  const [data, setData] = useState([
    {
      possiblity: "LSG Will Win the Toss(LSG vs DC)adv",
    },
    {
      possiblity: "Lunch Favourite Match Odds(LSG vs DC)adv",
    },
    {
      possiblity: "Hatrick wkts to be Taken(LSG vs DC)adv",
    },
    {
      possiblity: "Six Fours in an Over(LSG vs DC)adv",
    },
    {
      possiblity: "LSG Will Win the Toss(LSG vs DC)adv",
    },
    {
      possiblity: "LSG Will Win the Toss(LSG vs DC)adv",
    },
  ]);
  useEffect(() => {
    const id = event.event.id;
    getFancyCount(id).then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    const idsString = data
      .map((element) => element?.market?.id) // Extract IDs
      .filter((id) => id !== undefined) // Filter out undefined values
      .join(","); // Join them into a single string with commas
    setIds(idsString);
  }, [data]);
  useEffect(() => {
    if (ids !== "") {
      getFancyById(ids).then((res) => {
        setFancy(res);
      });
    }
  }, [ids]);
  return (
    <div className="py-3 fancy">
      <div className="container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="d-flex align-items-center justify-content-between text-white ff_inter fw-medium fs_16">
              Fancy
            </Accordion.Header>
            <Accordion.Body>
              {data?.map((value, index) => {
                return (
                  <div>
                    <div key={index} className="pb-1">
                      <div>
                        <p className="ff_heebo fs_12 mb-1 text-white">
                          {value?.market?.name}
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            style={{ height: "32px" }}
                            className="d-flex align-items-center w-100 gap-2 mt-2"
                          >
                            <div
                              onClick={() => setIsBetting(true)}
                              className="text-black text-center blue-clr-div py-1 px-2 w-100"
                            >
                              <p className="mb-0 ff_heebo fw-bold fs_15">
                                {fancy[index]?.back[0]?.price || "-"}
                              </p>
                              <p className="mb-0 ff_heebo fs_9">
                                {fancy[index]?.back[0]?.size || "-"}
                              </p>
                            </div>
                            <div
                              onClick={() => setIsBetting(true)}
                              className="text-black text-center pink-clr-div py-1 px-2 w-100"
                            >
                              <p className="mb-0 ff_heebo fw-bold fs_15">
                                {fancy[index]?.lay[0]?.price || "-"}
                              </p>
                              <p className="mb-0 ff_heebo fs_9">
                                {fancy[index]?.lay[0]?.size || "-"}
                              </p>
                            </div>
                          </div>
                          <div className="mx-3">
                            <p
                              style={{ lineHeight: "15px" }}
                              className="ff_inter fw-bold fs_15 mb-0 text-white"
                            >
                              Min:100
                            </p>
                            <p
                              style={{ lineHeight: "15px" }}
                              className="ff_inter fw-bold fs_15 mb-0 text-white"
                            >
                              Max:1L
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="match-odds-hr-line my-3"></div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div
        onClick={() => setIsBetting(false)}
        className={`body-overlay ${isBetting === true ? "d-block" : "d-none"}`}
      ></div>
      <BetSlip
        active={isBetting}
        setIsBettingFalse={() => setIsBetting(false)}
      />
    </div>
  );
};

export default Fancy;
