import React from "react";
import AppNavbar from "../home/AppNavbar";
import BottomNav from "../bottomnav/BottomNav";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <div>
      <div>
        <AppNavbar />
        <Outlet />
        <BottomNav />
      </div>
    </div>
  );
}

export default MainLayout;
