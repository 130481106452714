import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginAleartModal from "./Modals/LoginAleartModal";
const CommenButton = () => {
  const user = useSelector((state) => state.User.User);
  const [loginModal, setLoginModal] = useState(false);
  const navigation = useNavigate();

  const handleNavigate = (url) => {
    if (user) {
      navigation(url);
    } else {
      setLoginModal(true);
    }
  };
  return (
    <div>
      <div className="">
        <div className="d-flex align-items-center justify-content-between mt-4 gap-2">
          <div className="w-100" onClick={() => handleNavigate("/deposite")}>
            <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
              Deposit
            </button>
          </div>
          <div className="w-100" onClick={() => handleNavigate("/withdrawal")}>
            <button className="px-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100">
              Withdrawal
            </button>
          </div>
        </div>
      </div>
      <LoginAleartModal open={loginModal} close={() => setLoginModal(false)} />
    </div>
  );
};

export default CommenButton;
