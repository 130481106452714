import React from "react";
import footballIcon from "../../assets/images/svg/footballIcon.svg";
import whiteCricketStumps from "../../assets/images/svg/whiteCricketStumps.svg";
import whiteFootballIcon from "../../assets/images/svg/whiteFootballIcon.svg";
import whiteShuttleIcon from "../../assets/images/svg/whiteTennisRacketIcon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeletion from "../skeletion/Skeletion";

const Categories = () => {
  const Categoires = useSelector((state) => state.SportsRadar.allSports);

  // Create a copy of the array and sort it
  const orderedCategories = [...Categoires].sort((a, b) => {
    if (a.name.toLowerCase() === "cricket") return -1;
    if (b.name.toLowerCase() === "cricket") return 1;
    if (a.name.toLowerCase() === "soccer") return -1;
    if (b.name.toLowerCase() === "soccer") return 1;
    return 0;
  });

  return (
    <div className="">
      <div className="container">
        <div className="my-4">
          <div className="d-flex justify-content-between align-items-center px-2">
            <div className="d-flex align-items-center gap-2">
              <img src={footballIcon} alt="football" />
              <p className="text-white mb-0 ff_inter fw-medium fs_16">
                All Sports
              </p>
            </div>
          </div>
        </div>
        <div className="container ms-auto overflow-auto px-0 sports-casino-scrollbar">
          <div className="all-sports-games-div ">
            <div className="d-flex gap-3">
              {orderedCategories.length > 0 ? orderedCategories.map((value, index) => (
                <Link to={"/Matches/" + value?.id} key={index}>
                  <div className="bg_gray_linear sports-games-box rounded-2 d-flex flex-column align-items-center justify-content-center">
                    <div className="text-center">
                      <img src={whiteCricketStumps} alt={value.sports} />
                      <p className="mb-0 ff_inter fw-medium fs_10 text-white mt-1">
                        {value.name}
                      </p>
                    </div>
                  </div>
                </Link>
              )) :
                <div className="d-flex gap-3 mb-3">
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                  <Skeletion width={100} height={90} borderRadius={12} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
