import React from "react";

function Skeletion({ height, width, marginTop, borderRadius }) {
  return (
    <div>
      <div
        className="skeleton"
        style={{
          height: height,
          width: width,
          marginTop: marginTop,
          borderRadius: borderRadius,
        }}
      ></div>
    </div>
  );
}

export default Skeletion;
