import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCasion } from "../../Services/Casino";

// Define the async thunk
export const GetAllCasnio = createAsyncThunk("events/casino", async () => {
  const response = await getAllCasion();
  return response; // assuming response is in the correct format
});

const CasinoSlice = createSlice({
  name: "casino",
  initialState: {
    events: [],
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllCasnio.fulfilled, (state, action) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});
export default CasinoSlice.reducer;
