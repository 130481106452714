import io from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_BASEURL; // Define your socket URL in your environment variables

class SocketService {
  constructor() {
    this.socket = null;
  }

  connect() {
    if (!this.socket) {
      this.socket = io(SOCKET_URL, {
        transports: ["websocket"],
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      this.socket.on("connect", () => {
        console.log("Connected to socket server" , this.socket.id);
      });

      this.socket.on("disconnect", () => {
        // console.log("Disconnected from socket server");/
      });

      this.socket.on("connect_error", (error) => {
        // console.error("Connection error: ", error);/
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }
}

const socketService = new SocketService();
export default socketService;
